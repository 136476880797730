import * as algokit from "@algorandfoundation/algokit-utils";
import { useWallet } from "@txnlab/use-wallet";
import algosdk from "algosdk";

import { useState } from "react";
import { getAlgodConfigFromViteEnvironment } from "../../environment";

const Transact = ({ openModal, setModalState }) => {
  const [loading, setLoading] = useState(false);
  const [receiverAddress, setReceiverAddress] = useState("");

  const algodConfig = getAlgodConfigFromViteEnvironment();
  const algodClient = algokit.getAlgoClient({
    server: algodConfig.server,
    port: algodConfig.port,
    token: algodConfig.token,
  });

  const { signer, activeAddress, signTransactions, sendTransactions } =
    useWallet();

  const handleSubmitAlgo = async () => {
    setLoading(true);

    if (!signer || !activeAddress) {
      //   enqueueSnackbar("Please connect wallet first", { variant: "warning" });
      return;
    }

    const suggestedParams = await algodClient.getTransactionParams().do();

    const transaction = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
      from: activeAddress,
      to: receiverAddress,
      amount: 1e6,
      suggestedParams,
    });

    const encodedTransaction = algosdk.encodeUnsignedTransaction(transaction);

    const signedTransactions = await signTransactions([encodedTransaction]);

    const waitRoundsToConfirm = 4;

    try {
      console.log("Sending transaction...", { variant: "info" });
      const { id } = await sendTransactions(
        signedTransactions,
        waitRoundsToConfirm
      );
      console.log(`Transaction sent: ${id}`, { variant: "success" });
      setReceiverAddress("");
    } catch (e) {
      console.log("Failed to send transaction", { variant: "error" });
    }

    setLoading(false);
  };

  return (
    <dialog
      id="transact_modal"
      className={`modal ${openModal ? "modal-open" : ""} bg-slate-200`}
      style={{ display: openModal ? "block" : "none" }}
    >
      <form method="dialog" className="modal-box">
        <h3 className="font-bold text-lg">Send payment transaction</h3>
        <br />
        <input
          type="text"
          data-test-id="receiver-address"
          placeholder="Provide wallet address"
          className="input input-bordered w-full"
          value={receiverAddress}
          onChange={(e) => {
            setReceiverAddress(e.target.value);
          }}
        />
        <div className="modal-action grid">
          <button className="btn" onClick={() => setModalState(!openModal)}>
            Close
          </button>
          <button
            data-test-id="send-algo"
            className={`btn ${
              receiverAddress.length === 58 ? "" : "btn-disabled"
            } lo`}
            onClick={handleSubmitAlgo}
          >
            {loading ? (
              <span className="loading loading-spinner" />
            ) : (
              "Send 1 Algo"
            )}
          </button>
        </div>
      </form>
    </dialog>
  );
};

export default Transact;
