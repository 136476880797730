import "./App.css";
import { Summary } from "./components/summary.component";
import { Galaxy } from "./pages/galaxy/galaxy.page";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Blog } from "./pages/blog.component";
import { SideContainer } from "./components/side/side-container.component";
import Resume from "./pages/resume/resume.page";

function App() {
  const router = createBrowserRouter([
    {
      element: <Resume />,
      path: "",
    },
    {
      element: <Galaxy />,
      path: "galaxy",
    },
    {
      element: <Blog />,
      path: "blog",
    },
  ]);
  return (
    <main className="flex max-sm:flex-wrap">
      <SideContainer></SideContainer>
      <section className="flex-1 w-full bg-primary-dark">
        <Summary></Summary>
        <RouterProvider router={router}></RouterProvider>
      </section>
    </main>
  );
}

export default App;
