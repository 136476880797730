import tw from "tailwind-styled-components";
import { jobs } from "../../utility/job.library";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faDiamondExclamation,
  faEarthAmerica,
  faXmarkCircle,
} from "@fortawesome/pro-duotone-svg-icons";
import arrowRight from "../../assets/6446272.png";
import { transform } from "../../utility/template.utility";
import ProjectSummary from "../../components/project-work.component";

const ResumeContainerStyled = tw.section`bg-primary-dark lg:px-8 pt-2 max-sm:h-auto flex-wrap font-futara background relative h-[calc(100vh-270px)] flex lg:overflow-hidden`;
const JobDetailStyled = tw.div`max-sm:w-screen max-sm:h-auto shadow-lg bg-primary-lighter absolute top-0 right-0 p-8 overflow-y-auto h-full w-[450px] lg:relative lg:top-0 lg:right-0 lg:w-auto bg-primary-dark basis-[450px] xl:basis-[600px] text-white ml-8
[&::-webkit-scrollbar]:w-2
[&::-webkit-scrollbar-track]:rounded-full
[&::-webkit-scrollbar-track]:bg-gray-100
[&::-webkit-scrollbar-thumb]:rounded-full
[&::-webkit-scrollbar-thumb]:bg-gray-300
dark:[&::-webkit-scrollbar-track]:bg-slate-700
dark:[&::-webkit-scrollbar-thumb]:bg-slate-500`;
const JobEmptyStyled = tw.div`max-sm:w-screen shadow-lg absolute top-0 right-0 hidden lg:block lg:relative lg:top-0 lg:right-0 basis-[450px] xl:basis-[600px] w-full text-white ml-8 mt-[200px] text-2xl`;
const JobContainerStyled = tw.div`flex-1 max-sm:h-auto h-full overflow-y-auto lg:pr-2
[&::-webkit-scrollbar]:w-2
[&::-webkit-scrollbar-track]:rounded-full
[&::-webkit-scrollbar-track]:bg-gray-100
[&::-webkit-scrollbar-thumb]:rounded-full
[&::-webkit-scrollbar-thumb]:bg-gray-300
dark:[&::-webkit-scrollbar-track]:bg-slate-700
dark:[&::-webkit-scrollbar-thumb]:bg-slate-500`;
const JobCardStyled = tw.div`flex-1 max-sm:basis-[max-content] bg-primary-lighter/5 shadow-lg rounded-sm text-white overflow-hidden lg:mr-2 p-4 cursor-pointer flex flex-col ${(
  props
) => (props.active ? "bg-primary-lighter/10" : "")}`;
const IdentifyStyled = tw.span`text-gray-400 inline-block`;

const JobSubDetails = ({ job }) => {
  return (
    <>
      <div className="text-lg xl:text-lg text-gray-400 mb-4 pl-2 flex justify-between">
        <span className="">
          <FontAwesomeIcon icon={faCalendarDays} /> {job.dates}{" "}
        </span>
        <span className="">
          <FontAwesomeIcon icon={faEarthAmerica} /> {job.location}{" "}
        </span>
      </div>
      {job.term ? (
        <div className="text-md">
          <IdentifyStyled>Terms:&nbsp;</IdentifyStyled>
          {job.term}
        </div>
      ) : null}
      {job.stack ? (
        <div className="text-md">
          <IdentifyStyled>Stack:&nbsp;</IdentifyStyled>
          {job.stack}
        </div>
      ) : null}
      {job.libraries ? (
        <div className="text-md mb-2">
          <IdentifyStyled>Libraries:&nbsp;</IdentifyStyled>
          {job.libraries}
        </div>
      ) : null}
    </>
  );
};

const generateJobCard = (job, onClickHandler, index, active) => {
  return (
    <JobCardStyled
      active={active}
      onClick={(e) => onClickHandler(job, index, e)}
    >
      <div className="flex-1">
        <div className="font-bold text-xl 2xl:text-2xl">
          {job.position} <span>@ {job.company}</span>
        </div>
        <JobSubDetails job={job} />
        <div className="text-md">{job.summary}</div>
      </div>
      <div className="text-right">Click For More &gt;</div>
    </JobCardStyled>
  );
};

export const Resume = () => {
  const [jobDetail, setJobDetail] = useState(null);

  const onClickHandler = (job, index, e) => {
    document.getElementById("detail_container").scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
    setJobDetail({ ...job, index });
  };

  return (
    <>
      <ProjectSummary />
      <ResumeContainerStyled id="detail_container">
        <JobContainerStyled>
          {jobs.map((job, index) => {
            return (
              <div className="flex items-center mb-4">
                {generateJobCard(
                  job,
                  onClickHandler,
                  index,
                  `${job.position}-${job.company}` ===
                    `${jobDetail?.position}-${jobDetail?.company}`
                )}
                <span className="inline-block h-full flex items-center w-6 h-6">
                  {`${job.position}-${job.company}` ===
                  `${jobDetail?.position}-${jobDetail?.company}` ? (
                    <img
                      className="animate-bounce-left w-full"
                      src={arrowRight}
                    />
                  ) : null}
                </span>
              </div>
            );
          })}
        </JobContainerStyled>
        {jobDetail ? (
          <JobDetailStyled>
            <div className="uppercase font-bold pb-2 lg:hidden flex justify-end">
              <span className="inline-block" onClick={() => setJobDetail(null)}>
                <FontAwesomeIcon icon={faXmarkCircle} /> Close
              </span>
            </div>
            <div className="font-bold text-xl xl:text-2xl">
              {jobDetail.position} <span>@ {jobDetail.company}</span>
            </div>
            <JobSubDetails job={jobDetail} />
            <div
              className="[&>ul]:list-disc [&>ul]:ml-8 [&>ul]:mb-8 [&>p]:mb-8 text-lg 2xl:text-xl "
              dangerouslySetInnerHTML={{
                __html: transform(jobDetail.description),
              }}
            ></div>
          </JobDetailStyled>
        ) : (
          <JobEmptyStyled>
            <div className="text-center">
              <span className="block">
                <FontAwesomeIcon
                  className="h-16 w-16"
                  icon={faDiamondExclamation}
                />
              </span>
              No Job Selected
            </div>
          </JobEmptyStyled>
        )}
      </ResumeContainerStyled>
    </>
  );
};

export default Resume;
